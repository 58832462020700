import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import golden from "../img/about/golden2.jpg";
import graduate from "../img/history/graduate.jpg";
import inputnaze from "../img/history/inputnaze.jpg";
import inputstart from "../img/history/inputstart.jpg";
import monozukuri2021 from "../img/history/monozukuri2021.jpg";
import inputre from "../img/history/inputre.jpg";
import japanopennagaoka from "../img/history/japanopennagaoka.jpg";
import bg from "../img/bg.jpg";
import rcap2021 from "../img/history/rcap2021.jpg";
import jo2023 from "../img/history/jo2023.jpg";
import monozukuri2023 from "../img/history/monozukuri2023.jpg";
import lecture2023 from "../img/history/lecture2023.jpg";
import owari from "../img/history/owari.jpg";



import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export const HTMLContent = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

const Content = ({ content, className }) => (
  <Layout>
    <div className="content">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <p className="has-text-weight-semibold is-size-4 has-text-centered">
            ヒストリー</p>
          <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "rgb(245, 245, 245)" }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(245, 245, 245)"
                }}
                date="2024年3月"
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title has-text-centered">
                NAZEとしての事業を終了
                </h3>
                <img
                  className="margin-btm-0"
                  src={owari}
                  alt=""
                  width="100%"
                  height="auto"
                />
                <p>INPUTというチームは活動を続けますが、NPO法人長岡産業活性化協会NAZEの事業としての活動は令和5年度で区切りを迎えました。これまでご協賛いただいたNAZEやスポンサーの皆様ありがとうございました。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "rgb(245, 245, 245)" }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(245, 245, 245)"
                }}
                date="2024年2月"
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title has-text-centered">
                長岡ものづくりフェア2024に参加
                </h3>
                <img
                  className="margin-btm-0"
                  src={monozukuri2023}
                  alt=""
                  width="100%"
                  height="auto"
                />
                <p>地域の子どもたちがロボットに触れる機会を増やすために、アオーレ長岡で開催された長岡ものづくりフェアに参加し、ロボットのデモンストレーションを実施した。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "rgb(245, 245, 245)" }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(245, 245, 245)"
                }}
                date="2023年8月"
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title has-text-centered">
                AI・ロボティクス勉強会を開催
                </h3>
                <img
                  className="margin-btm-0"
                  src={lecture2023}
                  alt=""
                  width="100%"
                  height="auto"
                />
                <p>スポンサー企業を中心とする長岡地域のものづくり企業の方向けに、勉強会を開催した。INPUTのロボット技術だけでなく、SSLと他のロボット競技会との違いを紹介した。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "rgb(245, 245, 245)" }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(245, 245, 245)"
                }}
                date="2023年5月"
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              >
                <h3 className="vertical-timeline-element-title has-text-centered">
                ロボカップジャパンオープン2023滋賀に参戦
                </h3>
                <img
                  className="margin-btm-0"
                  src={jo2023}
                  alt=""
                  width="100%"
                  height="auto"
                />
                <p>新型コロナ禍を挟み、4年ぶりに全国大会に参加した。予選リーグ敗退となったが、試合の中で新型ロボットの性能を評価することに成功した。</p>
            </VerticalTimelineElement>
          <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(245, 245, 245)" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(245, 245, 245)"
              }}
              date="2021年11月"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title has-text-centered">
              ロボカップアジアパシフィック2021あいちに参戦
              </h3>
              <img
                className="margin-btm-0"
                src={rcap2021}
                alt=""
                width="100%"
                height="auto"
              />
              <p>前回の全国大会から2年3ヶ月ぶりに大会が開催された。6チーム中予選5位、最終結果6位という結果に終わったが、前回の大会に比べて常に8台のロボットでファウルを取られることなく試合を進めることに成功した。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(245, 245, 245)" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(245, 245, 245)"
              }}
              date="2021年2月"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title has-text-centered">
                長岡ものづくりフェアオンラインにてエキシビションマッチを実施
              </h3>
              <img
                className="margin-btm-0"
                src={monozukuri2021}
                alt=""
                width="100%"
                height="auto"
              />
              <p>コロナ禍という情勢から大会の開催や観戦が難しく、大会を体験できる機会が減っているという状況を打破するため、INPUTの保有するロボットでの模擬的な試合を開催し、対戦の様子をケーブルテレビ（NCT）とYouTubeにてLIVE配信を実施した。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(245, 245, 245)" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(245, 245, 245)"
              }}
              date="2019年8月"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title has-text-centered">
                ジャパンオープンながおかに参加
              </h3>
              <img
                className="margin-btm-0"
                src={bg}
                alt=""
                width="100%"
                height="auto"
              />
              <p>チームとしての初めての公式戦は長岡開催という好天気に見舞われた。チーム結成1年目、限られた開発時間の中、初出場にして8台のロボットを動かし、パスをもこなし、1勝を収める。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(245, 245, 245)" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(245, 245, 245)"
              }}
              date="2019年8月"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title has-text-centered">
                長岡で初のロボカップ全国大会が開催
              </h3>
              <img
                className="margin-btm-0"
                src={japanopennagaoka}
                alt=""
                width="100%"
                height="auto"
              />
              <p>INPUTのロボカップジュニア世界大会優勝から、長岡でロボットに力を入れる流れが出来たからか、長岡市がロボカップジャパンオープンの開催地に採択される。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(245, 245, 245)" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(245, 245, 245)"
              }}
              date="2019年1月"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title has-text-centered">
                NAZEの事業として活動開始
              </h3>
              <img
                className="margin-btm-0"
                src={inputnaze}
                alt=""
                width="100%"
                height="auto"
              />
              <p>NPO法人長岡産業活性化協会NAZEの事業として活動を開始。最初は、モノづくりフェスタのロボットデモから活動をはじめました。
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(245, 245, 245)" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(245, 245, 245)"
              }}
              date="2018年8月"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title has-text-centered">
                ロボカップSSLチームとして「INPUT」再結成
              </h3>
              <img
                className="margin-btm-0"
                src={inputre}
                alt=""
                width="100%"
                height="auto"
              />
              <p>長岡高専のロボカップジュニアチーム「INPUT」の世界優勝のほか、その前後の世代の世界大会出場や、世界大会準優勝を果たしたメンバーを中心にロボカップサッカースモールサイズリーグのチーム「INPUT」を再結成した。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(245, 245, 245)" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(245, 245, 245)"
              }}
              date="2018年3月"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title has-text-centered">
                ロボカップジュニアサッカー「INPUT」事実上解散
              </h3>
              <img
                className="margin-btm-0"
                src={graduate}
                alt=""
                width="100%"
                height="auto"
              />
              <p>世界大会優勝チーム「INPUT」のメンバーは長岡高専を卒業し、事実上解散となった。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(245, 245, 245)" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(245, 245, 245)"
              }}
              date="2017年7月"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title has-text-centered">
                ロボカップジュニアサッカー「INPUT」世界大会優勝
              </h3>
              <img
                className="margin-btm-0"
                src={golden}
                alt=""
                width="100%"
                height="auto"
              />
              <p>ロボカップジュニアサッカーチーム「INPUT」が2017年に行われたロボカップジュニアの世界大会で優勝し、世界チャンピョンになる。長岡市表彰を頂く。</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(245, 245, 245)" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(245, 245, 245)"
              }}
              date="2015年"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title has-text-centered">
                ロボカップジュニアサッカー「INPUT」結成
              </h3>
              <img
                className="margin-btm-0"
                src={inputstart}
                alt=""
                width="100%"
                height="auto"
              />
              <p>ロボカップ世界優勝を目指し、ロボカップジュニアサッカーチーム「INPUT」が結成された。</p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div>
    </div>
  </Layout>
);

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
